<template>
  <div class="bg-black-800 w-full text-white">
    <NuxtPage class="max-w-[2560px] m-auto z-10 relative" />
    <ClientOnly>
      <GModal v-model="showResetPassword" sticky :show-close="false">
        <ModalResetPassword @close="showResetPassword=false" />
      </GModal>
    </ClientOnly>
  </div>
</template>

<script>
// eslint-disable-next-line promise/param-names
const delay = ms => new Promise(r => setTimeout(r, ms));

export default {
  emits: ['login', 'error'],
  setup() {
    const CFIP = useCookie('CF-IPCountry')
    return { CFIP }
  },
  data() {
    return {
      showResetPassword: false,
      loading: false,
      reason: null,
      beacon: false,
      handledIdle: false,
    }
  },
  watch: {
    isSessionVerified(v) {
      if (v) {
        this.$store.user.GetUser();
      } else if (this.isAuthed) {
        // show 2fa modal?
      }
    },
    user(v) {
      if (v && v.resetPassword) {
        this.showResetPassword = true;
      }
    }
  },
  beforeMount() {
    if (this.CFIP) {
      this.$store.base.SetCountry(this.CFIP);
    }
    this.initApp();
  },
  mounted() {
    this.$mitt.on('network-idle', () => {
      this.networkIsIdle();
    });

    this.$mitt.on('open-beacon', () => {
      if (!this.beacon) {
        this.mountBeacon();
      }
      this.openBeacon();
    });

    this.$mitt.on('open-support', () => {
      if (!this.beacon) {
        this.mountBeacon();
      }
      this.openSupport();
    });

    this.$mitt.on('open-calendly', () => {
      if (!window.Calendly) {
        this.mountCalendly();
      }
      this.openCalendly();
    });

    this.detectIdle();

    setTimeout(() => {
      this.networkIsIdle();
    }, 5000);
  },
  methods: {
    emitNetworkIdle() {
      this.$mitt.emit('network-idle');
      this.networkIsIdle();
      this.$store.base._idle = true;
    },
    networkIsIdle() {
      if (this.handledIdle) {
        return;
      }
      this.handledIdle = true;
      this.beconInit();
      setTimeout(() => {
        this.mountBeacon();
      }, 100);
      this.loadCalendlyStyles();
      if (!window.Calendly) {
        this.mountCalendly();
      }
    },
    detectIdle() {
      let timer;

      const observer = new PerformanceObserver((items) => {
        items
          .getEntries()
          .filter(({ initiatorType }) => initiatorType === 'fetch')
          .forEach(() => {
            if (timer) {
              clearTimeout(timer);
            }
            timer = setTimeout(() => this.emitNetworkIdle(), 2000);
          });
      });

      observer.observe({
        entryTypes: ['resource'],
      });
    },
    async openCalendly() {
      if (!window.Calendly) {
        await delay(500);
        return this.openCalendly();
      }

      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/mattepaint/studio-plans-overview',
      });
    },
    loadCalendlyStyles() {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://assets.calendly.com/assets/external/widget.css';
      document.head.appendChild(link);
    },
    mountCalendly() {
      const s = document.createElement('script');
      s.src = 'https://assets.calendly.com/assets/external/widget.js';
      s.type = 'text/javascript'
      s.async = !0;
      this.$el.appendChild(s);
    },
    async mountBeacon() {
      if (this.beacon) {
        return Promise.resolve();
      }
      if (!window.Beacon) {
        this.beconInit();
        await delay(500);
        return this.mountBeacon();
      }
      window.Beacon('init', 'a8072d9b-b804-44ab-abf8-36392971f10c');
      this.beacon = true;
    },
    async openBeacon() {
      if (!window.Beacon) {
        await delay(100);
        return this.openBeacon();
      }
      window.Beacon('open');
    },
    async openSupport() {
      if (!window.Beacon) {
        await delay(100);
        return this.openSupport();
      }
      window.Beacon('open');
      setTimeout(() => {
        window.Beacon('navigate', '/ask/chat/');
      }, 0);
    },
    beconInit() {
      if (window.Beacon) {
        return;
      }
      // eslint-disable-next-line no-unused-expressions, no-sequences
      !(function(e, t, n) { function a() { let e = t.getElementsByTagName('script')[0]; let n = t.createElement('script'); n.type = 'text/javascript', n.async = !0, n.src = 'https://beacon-v2.helpscout.net', e.parentNode.insertBefore(n, e) } if (e.Beacon = n = function(t, n, a) { e.Beacon.readyQueue.push({ method: t, options: n, data: a }) }, n.readyQueue = [], t.readyState === 'complete') { return a(); } e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1) }(window, document, window.Beacon || function() {}));
    },
  }
}
</script>